<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-0 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>
    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
          md="8"
          class="px-1"
          order="2"
          order-md="1"
        >
          <b-form-group
            label="Search"
            label-for="filter_search"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filter_search"
              v-model="tableReplenishment.filter.search"
              type="text"
              size="sm"
              placeholder="search here"
              debounce="1000"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="6"
          md="2"
          class="px-1"
          order="1"
          order-md="2"
        >
          <b-form-group
            label="Date From"
            label-for="filter_date_from"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filter_date_from"
              v-model="tableReplenishment.filter.date_from"
              size="sm"
              type="date"
              :max="tableReplenishment.filter.date_to"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="6"
          md="2"
          class="px-1"
          order="1"
          order-md="2"
        >
          <b-form-group
            label="Date To"
            label-for="filter_date_to"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filter_date_to"
              v-model="tableReplenishment.filter.date_to"
              size="sm"
              type="date"
              :min="tableReplenishment.filter.date_from"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="px-1 mb-2"
          order="3"
        >
          <b-button
            size="sm"
            type="button"
            variant="primary"
            class="mr-1"
            :disabled="tableReplenishment.busy || exporter.busy"
            @click="tableReplenishmentRefresh"
          >
            <b-icon
              icon="arrow-clockwise"
              :animation="(tableReplenishment.busy ? 'spin' : null)"
            /> {{ tableReplenishment.busy ? 'Loading' : 'Reload' }}
          </b-button>
          <b-overlay
            rounded
            opacity="0.1"
            spinner-small
            class="d-inline"
            spinner-variant="warning"
            :show="exporter.busy"
          >
            <json-excel
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :class="((tableReplenishment.busy || exporter.busy || tableReplenishment.filter.date_to === null || tableReplenishment.filter.date_from === null) ? `btn btn-success btn-sm disabled` : `btn btn-success btn-sm`)"
              :fetch="excelReplenishmentProvider"
              :before-generate="onStartExcelExport"
              :before-finish="onFinishExcelExport"
              :fields="exporter.excel.fields"
              :escape-csv="exporter.excel.escape"
              :type="exporter.excel.type"
              :header="exporter.excel.header"
              :footer="exporter.excel.footer"
              :name="excelFileName"
              worksheet="Replenishments"
            >
              {{ exporter.busy ? 'Exporting' : 'Export As Excel' }}
            </json-excel>
          </b-overlay>
        </b-col>

      </b-row>

      <b-row class="mt-1">
        <b-col
          cols="12"
          class="px-1"
        >
          <b-table
            ref="tableReplenishment"
            hover
            small
            striped
            bordered
            responsive
            show-empty
            :stacked="isMobile()"
            :per-page="tableReplenishment.perPage"
            :current-page="tableReplenishment.currentPage"
            :items="tableReplenishmentProvider"
            :fields="tableReplenishment.fields"
            :sort-by.sync="tableReplenishment.sortBy"
            :sort-desc.sync="tableReplenishment.sortDesc"
            :sort-direction="tableReplenishment.sortDirection"
            :filter="tableReplenishment.filter"
            :filter-included-fields="tableReplenishment.filterOn"
            :busy="tableReplenishment.busy"
          >
            <template #cell(index)="{ index }">
              {{ tableNumber(tableReplenishment, index) }}
            </template>

            <template #cell()="{ value }">
              <div class="text-wrap text-md-nowrap">
                {{ value }}
              </div>
            </template>

            <template #cell(amount)="{ value }">
              <div class="text-right">
                {{ numberFormatter(value) }}
              </div>
            </template>

            <template #table-busy>
              <div class="text-center py-5">
                <b-icon
                  icon="stopwatch"
                  font-scale="5"
                  animation="cylon"
                />
                <p class="h3 py-2">
                  <strong>Loading . . .</strong>
                </p>
              </div>
            </template>

          </b-table>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="mt-1 mb-2 px-1"
        >
          <b-select
            v-model="tableReplenishment.perPage"
            :options="tableReplenishment.pageOptions"
            size="sm"
            class="w-100 w-md-25"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
        >
          <b-pagination
            v-model="tableReplenishment.currentPage"
            pills
            last-number
            first-number
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
            :total-rows="tableReplenishment.totalRows"
            :per-page="tableReplenishment.perPage"
          />
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
// import QUERY from 'lodash'
import { core } from '@/config/pluginInit'
import { SMerchantReplenishment } from '@/services'
import formatter from '@/mixins/formatter'
import Ripple from 'vue-ripple-directive'
import misc from '@/mixins/misc'

export default {
  name: 'MerchantReplenishments',

  middleware: ['auth', 'merchant'],

  directives: {
    Ripple
  },

  mixins: [misc, formatter],

  metaInfo: () => ({
    title: 'Replenishments'
  }),

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      tableReplenishment: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null,
          date_from: null,
          date_to: null
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 1, key: 'created_at', class: 'min-w-150px', formatter: this.dateTimeFormatter, sortable: true },
          { mobile: 2, key: 'amount', class: 'min-w-150px' },
          { mobile: 3, key: 'remarks', class: 'min-w-250px' },
          { mobile: 4, key: 'updated_at', class: 'min-w-150px', formatter: this.dateTimeFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      },
      exporter: {
        busy: false,
        pdf: null,
        excel: {
          header: [],
          escape: false,
          type: 'xls',
          fields: {
            '#': {
              field: 'page_number'
            },
            'Created At': {
              field: 'created_at',
              callback: createdAt => `="${this.dateTimeFormatter(createdAt)}"`
            },
            Position: {
              field: 'position'
            },
            Amount: {
              field: 'amount',
              callback: amount => this.numberFormatter(amount)
            },
            Remarks: {
              field: 'status'
            },
            'Updated At': {
              field: 'updated_at',
              callback: completedAt => `="${this.dateTimeFormatter(completedAt)}"`
            }
          },
          footer: []
        }
      }
    }
  },

  computed: {
    excelFileName () {
      const dateFrom = this.dateFormatter(this.tableReplenishment.filter.date_from)
      const dateTo = this.dateFormatter(this.tableReplenishment.filter.date_to)

      return `Replenishments ( ${dateFrom} to ${dateTo} )`
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async tableReplenishmentProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableReplenishment.busy = true
      return await SMerchantReplenishment.getReports(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search,
          filter_date_from: filter.date_from,
          filter_date_to: filter.date_to
        })
      ).then(({ data }) => {
        this.tableReplenishment.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableReplenishment.busy = false
      })
    },

    tableReplenishmentRefresh () {
      this.$refs.tableReplenishment.refresh()
    },

    async excelReplenishmentProvider () {
      if (this.exporter.busy || this.tableReplenishment.busy || this.tableReplenishment.filter.date_from === null || this.tableReplenishment.filter.date_to === null) {
        return null
      }
      this.exporter.busy = true
      return await SMerchantReplenishment.getExports(
        this.objectToUrl({
          filter_text: this.tableReplenishment.filter.search,
          filter_date_from: this.tableReplenishment.filter.date_from,
          filter_date_to: this.tableReplenishment.filter.date_to
        })
      ).then(({ data }) => {
        if (data.items.length <= 0) {
          this.swalInvalid(
            'No available data to be Exported. Try changing the date range.',
            'Notice'
          )
        }
        return data.items.map((item, i) => {
          item.page_number = Number(i) + 1
          return item
        })
      }).catch(() => []).finally(() => {
        this.exporter.busy = false
      })
    },

    onStartExcelExport () {
      if (this.exporter.busy || this.tableReplenishment.busy || this.tableReplenishment.filter.date_from === null || this.tableReplenishment.filter.date_to === null) {
        return null
      }

      const dateFrom = this.dateTimeFormatter(this.tableReplenishment.filter.date_from)
      const dateTo = this.dateTimeFormatter(this.tableReplenishment.filter.date_to)

      this.exporter.excel.header = [
        'Replenishments',
        `${dateFrom} to ${dateTo}`,
        '&nbsp;'
      ]
    },

    onFinishExcelExport () {
    }
  }
}
</script>
